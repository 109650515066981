import React from "react";
import {Layout} from "antd";
import dayjs from 'dayjs';

export default function Footer(props: any) {
    return (
        <Layout.Footer style={{padding: 24}}>
            © 2021 - {dayjs().year()} ITP-Consult Gesellschaft für IT-Beratung mbH
        </Layout.Footer>
    );
}