import React from 'react';
import {ConfigProvider} from "antd";
import deDE from 'antd/lib/locale/de_DE';

import AppLayout from "./layout";

import './style/app.css'
import {RegistrationView} from "./views/registration-view";

export default function App(props: any) {
    return (
        <ConfigProvider
            locale={deDE}
            theme={{
                token: {
                    colorPrimary: "#e30613",
                    fontSize: 20,
                }
            }}
        >
            <AppLayout>
                <RegistrationView/>
            </AppLayout>
        </ConfigProvider>
    )
}
