import {QRView} from "../components/qr";
import {Button, Card, DatePicker, Form, Input, Radio, Space, Steps} from "antd";
import {InfoCircleOutlined, PrinterOutlined, UserAddOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {IconSet} from "../components/icons";
import dayjs from "dayjs";

export function RegistrationView() {

    const [page, setPage] = useState(0)
    const [form] = Form.useForm();
    const info = (
        <div>
            <h1>Information</h1>
            <p>
                Hier können Sie Ihre Anmeldeinformationen vorab erfassen.
            </p>
            <p>
                Die Daten werden für die Anmeldung lokal auf Ihrem Gerät erfasst und nicht an den Server übertragen.
                Alle Informationen werden in den QR-Code gespeichert und können bei der Anmeldung gescannt werden.
            </p>
            <p>
                Sie können die Daten auch direkt bei der Anmeldung lassen erfassen.
            </p>
        </div>
    )

    const form_card = (
        <div>
            <h1>Registrierung</h1>
            <p>
                Bitte tragen Sie Ihre Daten ein.
            </p>
            <Form
                form={form}
                layout={'horizontal'}
                labelCol={{span: 6}}
                wrapperCol={{span: 14}}

            >
                <Form.Item
                    label={'Vorname'}
                    name={'firstname'}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={'Nachname'}
                    name={'surname'}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={'Geschlecht'}
                    name={'gender'}
                >
                    <Radio.Group
                        size={'middle'}
                        style={{width: '100%'}}
                    >
                        <Radio.Button
                            value={1}
                            style={{
                                width: 'calc((50% - 4px) / 3)', textAlign: 'center',
                            }}
                        ><IconSet type={'m'}/></Radio.Button>
                        <Radio.Button
                            value={2}
                            style={{
                                width: 'calc((50% - 4px) / 3)', textAlign: 'center',
                            }}
                        ><IconSet type={'w'}/></Radio.Button>
                        <Radio.Button
                            value={3}
                            style={{
                                width: 'calc((50% - 4px) / 3)', textAlign: 'center',
                            }}
                        ><IconSet type={'d'}/></Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={'Geburtstag'}
                    name={'birthday'}
                >
                    <Input
                        type={'date'}
                           style={{height: 32}}
                    />
                </Form.Item>
                <Form.Item
                    help={'Telefonnummer oder Mail.'}
                    label={'Kontakt'}
                    name={'contact'}
                >
                    <Input/>
                </Form.Item>

            </Form>
        </div>
    )

    const print = (
        <div>
            <div className={'hideOnPrint'}>
            <h1>Drucken</h1>
            <p>
                Bitte drucken Sie diese Zusammenfassung aus oder speichern Sie diese auf Ihrem Smartphone.<br/>
                Zeigen Sie diese Zusammenfassung bei der Anmeldung vor.
            </p>
            </div>
            <QRView
                firstname={form.getFieldValue('firstname')}
                surname={form.getFieldValue('surname')}
                gender={form.getFieldValue('gender')}
                birthday={form?.getFieldValue('birthday')?dayjs(form.getFieldValue('birthday')).format('DD.MM.YYYY'):undefined}
                contact={form.getFieldValue('contact')}
            />
        </div>
    )


    return (
        <center>
            <Card
                title={
                    <Steps
                        current={page}
                        style={{
                            width: '100%',
                            marginTop: window.innerWidth >= 577 ? 0 : 24,
                        }}
                        onChange={(current) => {
                            setPage(current)
                        }}
                        items={
                            [
                                {
                                    title: "Information",
                                    icon: <InfoCircleOutlined />
                                },
                                {
                                    title: "Registrierung",
                                    icon: <UserAddOutlined />
                                },
                                {
                                    title: "Drucken",
                                    icon: <PrinterOutlined />
                                }
                            ]
                        }
                    />
                }
                style={{
                    maxWidth: 1024,
                    marginTop: 24,
                }}
                bodyStyle={{
                    overflowY: 'auto',
                }}
            >
                {[info, form_card, print][page]}
                <div className={'hideOnPrint'}>
                <br/>
                    <Space size={24} style={{
                        width: '100%',
                        justifyContent: 'flex-end',
                    }}>
                        {page < 2?<Button
                            type={'primary'}
                            children={"Weiter"}
                            onClick={() => setPage(page + 1)}
                        />:<Button
                            type={'primary'}
                            children={"Drucken"}
                            onClick={() => window.print()}
                        />
                        }
                        &nbsp;
                    </Space>
                </div>
            </Card>
        </center>
    )
}