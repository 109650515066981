import {QRCode} from "antd";

interface QRViewProps {
    "firstname": string | undefined,
    "surname": string | undefined,
    "gender": number | undefined,
    "birthday": string | undefined,
    "contact": string | undefined,
}

export function QRView(props: QRViewProps) {

    const size = Math.min((window.innerWidth * 0.9) - 72, window.innerHeight * 0.33);
    console.log(props)
    return (
        <div
            id={"qr-view"}
        >
        <center>
            <b>Zusammenfassung:</b>
            <p>Name: {props.firstname} {props.surname}</p>
            <p>Geburtstag: {props.birthday}</p>
            <p>Kontakt: {props.contact}</p>
            <QRCode
                size={size}
                errorLevel={"M"}
                value={
                    JSON.stringify(
                        {
                            "firstname": props.firstname,
                            "surname": props.surname,
                            "gender": props.gender,
                            "birthday": props.birthday,
                            "contact": props.contact,
                        }
                    )
                }
            />
        </center>
        </div>
    )
}