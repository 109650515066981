import {Layout, theme} from "antd";

const {useToken} = theme;

export default function Header() {
    const {token} = useToken();

    return (
        <Layout.Header
            style={{
                backgroundColor: token.colorPrimaryBg,
                overflow: 'hidden',
                paddingLeft: window.innerWidth >= 640 ? 50 : 15,
            }}
        >
            <h2
                style={{
                    color: token.colorPrimary,
                    margin: 0,
                    padding: 0,
                }}
            >Wellenworkflow {window.innerWidth >= 640?"- Online Registrierung":""}</h2>
        </Layout.Header>
    )
}